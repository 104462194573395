<template>
    <div class="flex flex-col justify-between my-5 h-full">
        <div>
            <ElementsGoBack @goBack="$emit('goBack')">Zurück zum Login</ElementsGoBack>
            <ElementsAlert :type="state.error.type" :active="state.error.visible">{{ state.error.message }}</ElementsAlert>
            <div v-if="state.error.type != 'success'">
                <h1 class="text-2xl mt-1 mb-2">Passwort vergessen?</h1>
                <p>Hier können Sie ihr Passwort zurücksetzen. Geben Sie dazu Ihre E-Mail ein.</p>
                <ElementsForm path="resetpassword" @submit="restetPasswordSubmit" v-slot="{ validation }">
                    <ElementsInput :validation="validation" type="email" path="user.email" label="E-Mail" placeholder="E-Mail" v-model="form.email" required />
                    <ElementsButton type="submit" :loading="state.loading" showAs="full">Weiter</ElementsButton>
                </ElementsForm>
            </div>
        </div>
    </div>
</template>
<script setup>
const emit = defineEmits(['goBack'])

const { forgotPassword } = useStrapiAuth()

const form = reactive({
    email: null,
})

const state = reactive({
    loading: false,
    error: {
        visible: false,
        message: '',
        type: 'warning',
    },
})
const restetPasswordSubmit = async () => {
    state.loading = true
    try {
        await forgotPassword(form)
        state.error.message = 'Wir haben Ihnen eine E-Mail mit einem Link zur Rücksetzung des Passworts gesendet.'
        state.error.type = 'success'
        state.error.visible = true    
    } catch (e) {
        state.loading = false
        state.error.message = 'Die Rücksetzung ist fehlgeschlagen. Bitte prüfen Sie ihre Angaben.'
        state.error.type = 'error'
        state.error.visible = true
    }
}
</script>